import { gql } from '@apollo/client';
import type { ContentfulTypedSysProps } from 'src/types/contentful';
import type { RichText } from 'src/types/RichText';

import { type ImageDataProps, fragments as imageFragments } from '../../../../components/Image';
import { type VideoDataProps, fragments as videoFragments } from '../../../../components/Video';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

const snapHomeHeroFragment = gql`
  fragment SnapHomeHeroFragment on SnapHomeHero {
    ...ContentfulSysId
    title
    title2
    title3
    media {
      __typename
      ... on Video {
        ...VideoAll
      }
      ... on Image {
        ...ImageAll
      }
    }
    body {
      json
    }
  }
  ${imageFragments.all}
  ${videoFragments.all}
  ${contentfulSysIdFragment}
`;

export interface SnapHomeHeroDataProps extends ContentfulTypedSysProps<'SnapHomeHero'> {
  title: string;
  title2?: string;
  title3?: string;
  media: ImageDataProps | VideoDataProps;
  body?: RichText;
}

// =================================================================================================
// SnapHomeHero Query
// =================================================================================================

export const snapHomeHeroQuery = gql`
  query SnapHomeHero($id: String!, $locale: String!, $preview: Boolean!) {
    snapHomeHero(id: $id, locale: $locale, preview: $preview) {
      ...SnapHomeHeroFragment
    }
  }
  ${snapHomeHeroFragment}
`;

export interface SnapHomeHeroQueryData {
  snapHomeHero: SnapHomeHeroDataProps;
}
