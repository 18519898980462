import { useImperativeEffect } from '@snapchat/core-browser';
import { type FC, useContext, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';

import { AppContext } from '../../../../AppContext';
import { logError, logEvent, SubscribedEventType } from '../../../../helpers/logging';
import { NoMatch } from '../../../../pages/NoMatch';
import { assetContainerCss, assetFrameCss } from './PoliticalAdAsset.styles';

const supportedVideoExtensions = ['mp4', 'mov'];
const gcsBucketUrl = 'https://storage.googleapis.com/ad-manager-political-ads-dump-shadow';

export const PoliticalAdAsset: FC<Partial<RouteComponentProps<{ assetId: string }>>> = ({
  match,
}) => {
  const assetId = match?.params.assetId;
  const { getCurrentUrl } = useContext(AppContext);
  const [hasLoadingError, setHasLoadingError] = useState(false);

  useImperativeEffect(() => {
    logEvent({
      subscribedEventType: SubscribedEventType.PAGE_LOAD,
    });
  }, [assetId]);

  if (!assetId) {
    return <NoMatch />;
  }

  const currentUrl = getCurrentUrl();

  const urlObj = new URL(currentUrl);
  const mediaType = urlObj.searchParams.get('mediaType');

  const assetSrc = `${gcsBucketUrl}/${assetId}.${mediaType}`;

  const handleError = () => {
    logError({
      component: 'PoliticalAdsAsset',
      action: 'Loading asset failed',
    });

    setHasLoadingError(true);
  };

  if (hasLoadingError) {
    return <NoMatch />;
  }

  const asset = supportedVideoExtensions.includes(mediaType || '') ? (
    <video src={assetSrc} autoPlay controls onError={handleError} />
  ) : (
    <img src={assetSrc} onError={handleError} alt="ad" />
  );

  return (
    <article className={assetContainerCss}>
      <section className={assetFrameCss}>{asset}</section>
    </article>
  );
};
