import { gql } from '@apollo/client';

import type { ContentfulTypedSysProps } from '../../../types/contentful';
import type { Items } from '../../../types/Items';
import { contentfulSysIdFragment } from '../../../utils/contentful/contentfulQuery';
import type { SideMenuDataItemProps } from '../components/SideMenu/types';

export interface SnapHomePageProps {
  snapHomePage: ContentfulTypedSysProps<'SnapHomePage'> & {
    navigationItemsCollection: Items<SideMenuDataItemProps>;
    blocksCollection: Items<{
      __typename: string;
      sys: {
        id: string;
      };
    }>;
  };
}

export const snapHomePageQuery = gql`
  query SnapHomePageQuery($id: String!, $preview: Boolean!, $locale: String!) {
    snapHomePage(id: $id, preview: $preview, locale: $locale) {
      ...ContentfulSysId
      navigationItemsCollection(limit: 20) {
        items {
          ...ContentfulSysId
          title {
            json
          }
          url
        }
      }
      blocksCollection {
        items {
          ...ContentfulSysId
        }
      }
    }
  }
  ${contentfulSysIdFragment}
`;
