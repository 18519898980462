import { css } from '@emotion/css';
import { m, nonMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

import { totalHeaderHeightCssVar } from '../../../../components/Header/headerSizeUtils';

export const itemsWrapperCss = css`
  display: none;
  min-width: 256px;

  ${nonMobileMediaQuery} {
    display: block;
  }
`;

export const itemListCss = css`
  list-style: none;
  position: sticky;
  top: var(${totalHeaderHeightCssVar});
`;

export const listItemCss = css`
  font-size: 26px;
  font-weight: 600;
  height: 54px;
  line-height: 54px;

  a {
    color: ${m('--global-header-navigator-item-color')};
    overflow: hidden;
    padding-inline-start: ${m('--spacing-xxxxl')};
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::before {
      background-color: ${m('--global-header-item-hover-color')};
      border-radius: ${m('--border-radius-xs')};
      content: ' ';
      height: 32px;
      left: 0;
      margin-top: 10px;
      position: absolute;
      visibility: hidden;
      width: 7px;

      *[dir='rtl'] & {
        left: unset;
        right: 0;
      }
    }

    &:hover::before,
    &:active::before {
      visibility: visible;
    }
  }
`;
