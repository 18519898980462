import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { LatestNewsBlock } from './LatestNewsBlock';
import type { NewsBlockDataHandlerProps } from './LatestNewsBlock.query';
import { newsBlockQuery } from './LatestNewsBlock.query';

export const LatestNewsBlockShallow: FC<{ id: string }> = ({ id }) => {
  /*
    TODO: move the query logic to the SnapHomePage component. We should only need a single query to
    fetch all of the content, except for the cross-space query for fetching Newsroom tiles.
  */
  const { data } = useContentfulQuery<NewsBlockDataHandlerProps, ContentfulIdVariable>(
    newsBlockQuery,
    {
      variables: { id },
    }
  );

  if (!data?.newsBlock) return null;

  return <LatestNewsBlock {...data?.newsBlock} />;
};
