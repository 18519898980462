import { gql } from '@apollo/client';

import type { Items } from '../../../../types/Items';
import type { RichText } from '../../../../types/RichText';

export const cookieInformationQuery = gql`
  query CookieInformation($preview: Boolean!, $locale: String!) {
    cookieModalCollection(limit: 1, preview: $preview, locale: $locale) {
      items {
        sys {
          publishedAt
        }
        cookieCategoriesCollection(limit: 13) {
          items {
            title
            cookiesCollection(limit: 50) {
              items {
                name
                provider {
                  json
                }
                domains {
                  json
                }
                purpose {
                  json
                }
                expiration {
                  json
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface CookieInformationDataProps {
  cookieModalCollection: Items<CookieModalShallowDataProps>;
}

export interface CookieModalShallowDataProps {
  sys: {
    publishedAt: string;
  };
  cookieCategoriesCollection: Items<CookieCategoriesShallowProps>;
}

export interface CookieCategoriesShallowProps {
  title: string;
  cookiesCollection: Items<CookieShallowProps>;
}

export interface CookieShallowProps {
  name: string;
  provider: RichText;
  domains: RichText;
  purpose: RichText;
  expiration: RichText;
}
