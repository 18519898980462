import type { FC } from 'react';

import { BlockShallow } from '../../../components/Block/BlockShallow';
import { type ContentfulIdVariable, useContentfulQuery } from '../../../hooks/useContentfulQuery';
import { CardBlockShallow } from '../components/CardBlock';
import { LatestNewsBlockShallow } from '../components/LatestNewsBlock';
import { SideMenu } from '../components/SideMenu/SideMenu';
import { SnapHomeHeroShallow } from '../components/SnapHomeHero';
import { SnapHomePageWrapper } from '../components/SnapHomePageWrapper';
import { type SnapHomePageProps, snapHomePageQuery } from './SnapHomePage.query';
import { contentContainerCss } from './SnapHomePage.styles';

export const SnapHomePage: FC<{ id: string }> = props => {
  const { data } = useContentfulQuery<SnapHomePageProps, ContentfulIdVariable>(snapHomePageQuery, {
    variables: { id: props.id },
  });

  const blocks = data?.snapHomePage.blocksCollection?.items ?? [];
  const navItems = data?.snapHomePage.navigationItemsCollection?.items ?? [];

  return (
    <SnapHomePageWrapper>
      {navItems.length ? <SideMenu navItems={navItems} /> : null}
      <div className={contentContainerCss}>
        {blocks?.map(block => {
          const id = block.sys.id;

          switch (block.__typename) {
            case 'SnapHomeHero':
              return <SnapHomeHeroShallow key={id} id={id} />;

            case 'CardBlock':
              return <CardBlockShallow key={id} id={id} />;

            case 'NewsBlock':
              return <LatestNewsBlockShallow key={id} id={id} />;

            case 'Block':
              return <BlockShallow key={id} {...block} />;

            default:
              return null;
          }
        })}
      </div>
    </SnapHomePageWrapper>
  );
};
