import type { Alignment } from '@snapchat/snap-design-system-marketing';
import { gql } from 'graphql-tag';

import { ctaFragment } from '../../../../components/CallToAction';
import type { CallToActionDataProps } from '../../../../components/CallToAction/types';
import { fragments as assetsFragments } from '../../../../components/Media';
import type { MediaDataProps } from '../../../../components/Media/types';
import type { ContentfulSysProps } from '../../../../types/contentful';
import type { Items } from '../../../../types/Items';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

const animatedMediaCardFragment = gql`
  fragment AnimatedMediaCardAll on AnimatedMediaCard {
    ...ContentfulSysId
    backgroundMedia {
      ...AssetAll
    }
    foregroundMedia {
      ...AssetAll
    }
    url
  }
  ${assetsFragments.all}
  ${contentfulSysIdFragment}
`;

const cardBlockFragment = gql`
  fragment CardBlockAll on CardBlock {
    ...ContentfulSysId
    eyebrow
    cardsCollection(limit: 4) {
      items {
        ...AnimatedMediaCardAll
      }
    }
    callsToActionCollection(limit: 9) {
      items {
        ... on CallToAction {
          ...CallToActionAll
        }
      }
    }
    textAlignment
    textAlignmentMobile
  }
  ${ctaFragment}
  ${contentfulSysIdFragment}
  ${animatedMediaCardFragment}
`;

export const cardBlockQuery = gql`
  query CardBlockQuery($preview: Boolean!, $locale: String!, $id: String!) {
    cardBlock(preview: $preview, locale: $locale, id: $id) {
      ...ContentfulSysId
      ...CardBlockAll
    }
  }
  ${contentfulSysIdFragment}
  ${cardBlockFragment}
`;

export type CardBlockDataHandlerProps = {
  cardBlock: CardBlockDataProps;
};

export type CardBlockDataProps = ContentfulSysProps & {
  callsToActionCollection?: Items<CallToActionDataProps>;
  cardsCollection: Items<CardBlockItemDataProps>;
  eyebrow?: string;
  textAlignment?: Alignment;
  textAlignmentMobile?: Alignment;
};

export type CardBlockItemDataProps = ContentfulSysProps & {
  backgroundMedia: MediaDataProps;
  foregroundMedia: MediaDataProps;
  url: string;
};
