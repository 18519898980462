import { css } from '@emotion/css';
import {
  CarouselFeatureClass,
  m,
  MotifComponent,
  nonMobileMediaQuery,
} from '@snapchat/snap-design-system-marketing';

import { totalHeaderHeightCssVar } from '../../../../components/Header/headerSizeUtils';

export const snapHomePageWrapperCss = css`
  display: flex;
  margin-top: calc(0px - var(${totalHeaderHeightCssVar}));
  min-height: 100vh;
  min-width: 100vw;
  padding-top: var(${totalHeaderHeightCssVar});

  /* TODO: determine whether there is a more appropriate place to put the styles below */

  /* Override styles of Carousel component for better alignment with custom components: CardBlock, NewsBlock */
  .${MotifComponent.CAROUSEL}.${CarouselFeatureClass.MultiView} > div {
    margin-inline-start: calc((${m('--carousel-card-mobile-grid-gap')} / 2) * -1);
    width: calc(100% + (${m('--carousel-card-mobile-grid-gap')} / 2));

    ${nonMobileMediaQuery} {
      margin-inline-start: calc((${m('--carousel-card-desktop-grid-gap')} / 2) * -1);
      width: calc(100% + (${m('--carousel-card-desktop-grid-gap')} / 2));
    }
  }
`;
