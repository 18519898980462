import { css } from '@emotion/css';
import { m, smallMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const animatedMediaCardForegroundImageCss = css`
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  position: relative;
  width: 65%;
`;

export const animatedMediaCardCss = css`
  align-items: center;
  border: 2px solid ${m('--palette-black-v50')};
  border-radius: ${m('--border-radius-l')};
  box-shadow: none;
  display: flex;
  justify-content: center;
  height: 185px;
  overflow: hidden;
  position: relative;
  transition: border-color 0.2s linear, box-shadow 0.2s linear;
  width: 100%;

  :hover {
    border-color: ${m('--palette-black-v200')};
    box-shadow: ${m('--box-shadow-m')};
  }

  ${smallMobileMediaQuery} {
    height: 148px;
  }
`;

export const animatedMediaCardWrapperCss = css`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  img {
    transition: transform 0.2s linear;
  }

  :hover img {
    transform: scale(1.2);
  }
`;

export const animatedMediaCardBackgroundImageCss = css`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const animatedMediaCardPictureCss = css`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
