import { css } from '@emotion/css';
import { m, twoColumnCollapseMediaQuery } from '@snapchat/snap-design-system-marketing';

export const cardContainerCss = css`
  display: grid;
  gap: ${m('--spacing-xl')};
  grid-template-columns: repeat(2, minmax(0, 1fr));

  ${twoColumnCollapseMediaQuery} {
    gap: ${m('--spacing-m')};
    grid-template-columns: minmax(0, 1fr);
  }
`;

export const cardContainerWithEyebrowCss = css`
  margin-top: ${m('--spacing-xl')};
`;

export const cardContainerWithCtasCss = css`
  margin-bottom: ${m('--spacing-l')};
`;
