import { css } from '@emotion/css';
import {
  m,
  MotifComponent,
  twoColumnCollapseMediaQuery,
} from '@snapchat/snap-design-system-marketing';

export const galleryContainerCss = css`
  ${twoColumnCollapseMediaQuery} {
    .${MotifComponent.GALLERY} > * {
      width: 100%;
    }
  }
`;

export const galleryContainerWithEyebrowCss = css`
  margin-top: ${m('--spacing-xl')};
`;
