import type { Motif } from '@snapchat/snap-design-system-marketing';
import {
  defaultMotif,
  m,
  mergeMotifs,
  MotifComponent,
  MotifScheme,
} from '@snapchat/snap-design-system-marketing';

/** Motif for the Snap.com homepage */
export const snapMotif: Motif = mergeMotifs(defaultMotif, {
  name: 'Snap',
  [MotifScheme.DEFAULT]: {
    name: 'Snap Yellow background',
    [MotifComponent.BUTTON]: {
      '--button-border-width': '2px',
      '--button-hover-shadow': m('--box-shadow-l'),
      '--button-secondary-bg-color': m('--primary-v100'),
      '--button-secondary-fg-color': m('--neutral-v700'),
      '--button-secondary-border-color': m('--neutral-v500'),
      '--button-secondary-hover-bg-color': m('--primary-v50'),
      '--button-secondary-hover-border-color': m('--neutral-v700'),
    },
    [MotifComponent.CAROUSEL]: {
      '--carousel-active-dot-color': m('--neutral-v700'),
      '--carousel-card-bg-color': m('--primary-v100'),
      '--carousel-card-hover-bg-color': m('--primary-v50'),
      '--carousel-card-border-width': '2px',
      '--carousel-card-border-color': m('--neutral-v500'),
      '--carousel-card-hover-border-color': m('--neutral-v700'),
      '--carousel-card-desktop-grid-gap': m('--spacing-xl'),
      '--carousel-card-box-shadow': 'none',
      '--carousel-card-hover-box-shadow': m('--box-shadow-m'),
    },
    [MotifComponent.SUMMARY_CARD]: {
      '--summary-card-bg-color': m('--primary-v100'),
      '--summary-card-hover-bg-color': m('--primary-v50'),
      '--summary-card-border-radius': m('--border-radius-l'),
      '--summary-card-border-width': '2px',
      '--summary-card-border-color': m('--neutral-v500'),
      '--summary-card-hover-border-color': m('--neutral-v700'),
      '--summary-card-box-shadow': 'none',
      '--summary-card-hover-box-shadow': m('--box-shadow-m'),
    },
    [MotifComponent.DROPDOWN_MENU]: {
      '--dropdown-button-border-radius': m('--border-radius-l'),
      '--dropdown-button-border-width': '2px',
      '--dropdown-button-border-color': m('--neutral-v500'),
      '--dropdown-button-hover-border-color': m('--neutral-v700'),
    },
  },
  [MotifScheme.SECONDARY]: {
    name: 'Snap Black background',
    [MotifComponent.BUTTON]: {
      '--button-border-width': '2px',
    },
    [MotifComponent.CAROUSEL]: {
      '--carousel-active-dot-color': m('--neutral-v0'),
      '--carousel-inactive-dot-color': 'rgba(133, 141, 148, 0.5)',
      '--carousel-card-bg-color': m('--neutral-v700'),
      '--carousel-card-hover-bg-color': m('--neutral-v625'),
      '--carousel-card-fg-color': m('--neutral-v0'),
      '--carousel-card-border-width': '2px',
      '--carousel-card-border-color': m('--neutral-v600'),
      '--carousel-card-hover-border-color': m('--neutral-v500'),
      '--carousel-card-desktop-grid-gap': m('--spacing-xl'),
      '--carousel-card-box-shadow': 'none',
      '--carousel-card-hover-box-shadow': m('--box-shadow-m'),
    },
    [MotifComponent.SUMMARY_CARD]: {
      '--summary-card-bg-color': m('--neutral-v700'),
      '--summary-card-hover-bg-color': m('--neutral-v625'),
      '--summary-card-fg-color': m('--neutral-v0'),
      '--summary-card-title-color': m('--neutral-v0'),
      '--summary-card-description-color': m('--neutral-v0'),
      '--summary-card-border-radius': m('--border-radius-l'),
      '--summary-card-border-width': '2px',
      '--summary-card-border-color': m('--neutral-v600'),
      '--summary-card-hover-border-color': m('--neutral-v500'),
      '--summary-card-box-shadow': 'none',
      '--summary-card-hover-box-shadow': m('--box-shadow-m'),
    },
  },
  [MotifScheme.TERTIARY]: {
    name: 'Snap White background',
    [MotifComponent.BUTTON]: {
      '--button-border-width': '2px',
      '--button-primary-border-color': m('--neutral-v500'),
      '--button-primary-hover-border-color': m('--neutral-v700'),
    },
    [MotifComponent.CAROUSEL]: {
      '--carousel-active-dot-color': m('--neutral-v700'),
      '--carousel-inactive-dot-color': 'rgba(133, 141, 148, 0.5)',
      '--carousel-card-bg-color': m('--neutral-v0'),
      '--carousel-card-hover-bg-color': m('--neutral-v100'),
      '--carousel-card-fg-color': m('--neutral-v700'),
      '--carousel-card-border-width': '2px',
      '--carousel-card-border-color': m('--neutral-v500'),
      '--carousel-card-hover-border-color': m('--neutral-v700'),
      '--carousel-card-desktop-grid-gap': m('--spacing-xl'),
      '--carousel-card-box-shadow': 'none',
      '--carousel-card-hover-box-shadow': m('--box-shadow-m'),
    },
    [MotifComponent.SUMMARY_CARD]: {
      '--summary-card-bg-color': m('--neutral-v0'),
      '--summary-card-hover-bg-color': m('--neutral-v100'),
      '--summary-card-fg-color': m('--neutral-v700'),
      '--summary-card-title-color': m('--neutral-v700'),
      '--summary-card-description-color': m('--neutral-v700'),
      '--summary-card-border-radius': m('--border-radius-l'),
      '--summary-card-border-width': '2px',
      '--summary-card-border-color': m('--neutral-v500'),
      '--summary-card-hover-border-color': m('--neutral-v700'),
      '--summary-card-box-shadow': 'none',
      '--summary-card-hover-box-shadow': m('--box-shadow-m'),
    },
  },
  [MotifScheme.QUATERNARY]: {
    name: 'Snap Gray background',
    [MotifComponent.BUTTON]: {
      '--button-border-width': '2px',
      '--button-primary-border-color': m('--neutral-v500'),
      '--button-primary-hover-border-color': m('--neutral-v700'),
      '--button-secondary-bg-color': m('--neutral-v700'),
      '--button-secondary-hover-bg-color': m('--neutral-v700'),
      '--button-secondary-border-color': m('--neutral-v700'),
      '--button-secondary-hover-border-color': m('--neutral-v700'),
      '--button-secondary-fg-color': m('--neutral-v0'),
    },
    [MotifComponent.CAROUSEL]: {
      '--carousel-active-dot-color': m('--neutral-v700'),
      '--carousel-inactive-dot-color': 'rgba(133, 141, 148, 0.5)',
      '--carousel-card-bg-color': m('--neutral-v150'),
      '--carousel-card-hover-bg-color': m('--neutral-v200'),
      '--carousel-card-fg-color': m('--neutral-v700'),
      '--carousel-card-border-width': '2px',
      '--carousel-card-border-color': m('--neutral-v500'),
      '--carousel-card-hover-border-color': m('--neutral-v700'),
      '--carousel-card-desktop-grid-gap': m('--spacing-xl'),
      '--carousel-card-box-shadow': 'none',
      '--carousel-card-hover-box-shadow': m('--box-shadow-m'),
    },
    [MotifComponent.SUMMARY_CARD]: {
      '--summary-card-bg-color': m('--neutral-v150'),
      '--summary-card-hover-bg-color': m('--neutral-v200'),
      '--summary-card-fg-color': m('--neutral-v700'),
      '--summary-card-title-color': m('--neutral-v700'),
      '--summary-card-description-color': m('--neutral-v700'),
      '--summary-card-border-radius': m('--border-radius-l'),
      '--summary-card-border-width': '2px',
      '--summary-card-border-color': m('--neutral-v500'),
      '--summary-card-hover-border-color': m('--neutral-v700'),
      '--summary-card-box-shadow': 'none',
      '--summary-card-hover-box-shadow': m('--box-shadow-m'),
    },
  },
});
