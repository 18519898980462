import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { CardBlock } from './CardBlock';
import { type CardBlockDataHandlerProps, cardBlockQuery } from './CardBlock.query';

export const CardBlockShallow: FC<{ id: string }> = props => {
  /*
    TODO: move the query logic to the SnapHomePage component. We should only need a single query to
    fetch all of the content, except for the cross-space query for fetching Newsroom tiles.
  */
  const { data } = useContentfulQuery<CardBlockDataHandlerProps, ContentfulIdVariable>(
    cardBlockQuery,
    {
      variables: { id: props.id },
    }
  );

  if (!data?.cardBlock) return null;

  return <CardBlock {...data.cardBlock} />;
};
