import { css } from '@emotion/css';
import { m } from '@snapchat/snap-design-system-marketing';

export const assetContainerCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - var(--total-header-height));
  min-height: calc(100dvh - var(--total-header-height));
  img,
  video {
    max-width: 300px;
    border-radius: ${m('--spacing-s')};
  }
`;

export const assetFrameCss = css`
  border-radius: ${m('--spacing-s')};
  box-shadow: 0 ${m('--spacing-xs')} ${m('--spacing-xl')} 0 rgba(0, 0, 0, 0.16);
  border: ${m('--spacing-s')} solid #fff;
`;
