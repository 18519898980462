import type { FetchPolicy, QueryHookOptions, QueryResult } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { Entity } from '@contentful/live-preview/dist/types';
import { useContext } from 'react';

import { AppContext } from '../../../AppContext';
import { tileCollectionQuery } from '../../../components/Gallery';
import { Config } from '../../../config';
import type {
  ContentfulQueryOptions,
  ContentfulQueryVariables,
} from '../../../hooks/useContentfulQuery';
import { useNewsroomContentfulContext } from '../context/useNewsroomContentfulContext';

export interface ContentfulContext {
  currentLocale: string;
  isPreview: boolean;
  isSSR: boolean;
}

/** Wrapper around `useQuery` hook that adds variables from the Newsroom contentful space */
export function useNewsroomContentfulQuery<
  TData extends Entity,
  TVariables = ContentfulQueryVariables
>(
  options?: ContentfulQueryOptions<TData, TVariables & ContentfulQueryVariables>
): QueryResult<TData, TVariables & ContentfulQueryVariables> {
  const { getCurrentUrl } = useContext(AppContext);

  // apply newsroom specific settings to query options
  const { locale, preview, fetchPolicy, apolloClient } = useNewsroomContentfulContext();

  const queryOptions: QueryHookOptions<TData, ContentfulQueryVariables & TVariables> = {
    client: apolloClient,
    fetchPolicy: fetchPolicy as FetchPolicy,
    ...options,
    context: { currentUrl: getCurrentUrl(), ...options?.context },
    // @ts-ignore: We assume that all queries require a 'locale' and 'preview' vars.
    variables: {
      preview,
      locale,
      ...options?.variables,
    },
    ssr: options?.ssr ?? Config.isSSR,
  };

  return useQuery(tileCollectionQuery, queryOptions);
}
