import type { FC } from 'react';

import {
  type ContentfulIdVariable,
  useContentfulQuery,
} from '../../../../hooks/useContentfulQuery';
import { SnapHomeHero } from './SnapHomeHero';
import { type SnapHomeHeroQueryData, snapHomeHeroQuery } from './SnapHomeHero.query';

export const SnapHomeHeroShallow: FC<{ id: string }> = props => {
  /*
    TODO: move the query logic to the SnapHomePage component. We should only need a single query to
    fetch all of the content, except for the cross-space query for fetching Newsroom tiles.
  */
  const { data } = useContentfulQuery<SnapHomeHeroQueryData, ContentfulIdVariable>(
    snapHomeHeroQuery,
    {
      variables: { id: props.id },
    }
  );

  if (!data?.snapHomeHero) return null;

  return <SnapHomeHero {...data?.snapHomeHero} />;
};
