import { cx } from '@emotion/css';
import { Alignment, Block as BlockSDS, useIsMobile } from '@snapchat/snap-design-system-marketing';
import { type FC, useMemo } from 'react';

import { useContentfulImages } from '../../../../../../contentful-client/src/useContentfulImages';
import { CallToAction } from '../../../../components/CallToAction';
import { AnimatedMediaCard } from '../AnimatedMediaCard';
import type { CardBlockDataProps } from './CardBlock.query';
import {
  cardContainerCss,
  cardContainerWithCtasCss,
  cardContainerWithEyebrowCss,
} from './CardBlock.styles';

export const CardBlock: FC<CardBlockDataProps> = ({
  callsToActionCollection,
  cardsCollection,
  eyebrow,
  textAlignment = Alignment.Center,
  textAlignmentMobile = Alignment.Center,
}) => {
  const { getImageSources } = useContentfulImages();
  const isMobile = useIsMobile();

  const numberOfColumns = useMemo(() => {
    if (isMobile) {
      return 1;
    }

    return cardsCollection?.items?.length === 3 ? 3 : 2;
  }, [isMobile, cardsCollection?.items?.length]);

  const callsToAction = callsToActionCollection?.items?.map(item => (
    <CallToAction key={item.sys.id} {...item} />
  ));

  return (
    <BlockSDS
      callsToAction={callsToAction}
      eyebrow={eyebrow}
      maxColumns={numberOfColumns}
      titleAlignment={textAlignment}
      titleAlignmentMobile={textAlignmentMobile}
    >
      <div
        className={cx(cardContainerCss, {
          [cardContainerWithEyebrowCss]: !!eyebrow,
          [cardContainerWithCtasCss]: !!callsToAction?.length,
        })}
      >
        {cardsCollection?.items?.map(item => (
          <AnimatedMediaCard
            key={item.sys.id}
            url={item.url}
            backgroundImageSources={getImageSources(item.backgroundMedia.url)}
            foregroundImageSources={getImageSources(item.foregroundMedia.url)}
          />
        ))}
      </div>
    </BlockSDS>
  );
};
