import { cx } from '@emotion/css';
import { Alignment, Block as BlockSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { CallToAction } from '../../../../components/CallToAction';
import type { NewsBlockDataProps } from './LatestNewsBlock.query';
import { galleryContainerCss, galleryContainerWithEyebrowCss } from './LatestNewsBlock.styles';
import { LatestNewsBlockGallery } from './LatestNewsBlockGallery';

export const LatestNewsBlock: FC<NewsBlockDataProps> = ({
  eyebrow,
  numberOfNewsItems,
  textAlignment = Alignment.Center,
  textAlignmentMobile = Alignment.Center,
  callsToActionCollection,
  newsReference,
  defaultImage,
}) => {
  // Gallery id is the last portion of the urn, after the final '/'
  const galleryId = newsReference?.sys?.urn.split('/').at(-1) ?? '';

  const callsToAction = callsToActionCollection?.items?.map(item => (
    <CallToAction key={item.sys.id} {...item} />
  ));

  return (
    <BlockSDS
      eyebrow={eyebrow}
      callsToAction={callsToAction}
      maxColumns={1}
      titleAlignment={textAlignment}
      titleAlignmentMobile={textAlignmentMobile}
    >
      {galleryId ? (
        /* TODO: Tweak the wrapping behavior of the gallery tiles.
         * As we are reserving some of the horizontal space for the Side Navigation component on the
         * snap.com home page, the default styles for gallery tiles don't use the right break point
         * to switch to single column layout. This means that the tiles can end up hard to read.
         */
        <div className={cx(galleryContainerCss, { [galleryContainerWithEyebrowCss]: !!eyebrow })}>
          <LatestNewsBlockGallery
            galleryId={galleryId}
            pageSize={numberOfNewsItems}
            defaultTileImage={defaultImage}
          />
        </div>
      ) : null}
    </BlockSDS>
  );
};
