import { useAsyncData } from '@snapchat/async-data-browser';
import { BrowserFeaturesContext } from '@snapchat/snap-design-system-marketing';
import { useContext, useMemo, useRef } from 'react';

import { AppContext } from '../../../AppContext';
import type { PaginationDataProps } from '../../../components/Gallery';
import { logger } from '../../../helpers/logging';

type GalleryData = {
  paginationData: PaginationDataProps | undefined;
  isLoading: boolean;
};

type GalleryTilesProps = {
  galleryId: string;
  locale: string;
  pageSize?: number;
};

/**
 * Returns the data needed to render the pagination and tiles components
 *
 * @param galleryId Id of the gallery we want to query
 * @param pageSize Number of results we want
 */
export function useNewsroomGalleryTiles({
  galleryId,
  locale,
  pageSize = 2,
}: GalleryTilesProps): GalleryData {
  const appContext = useContext(AppContext);
  const browserFeatures = useContext(BrowserFeaturesContext);
  const previousTotal = useRef(0);

  const params = useMemo(() => {
    const params = new URLSearchParams();

    params.append('galleryId', galleryId);
    params.append('pageSize', `${pageSize}`);
    params.append('sortBy', 'By Date');
    params.append('spaceId', 'newsroom');
    params.append('page', '0');
    params.append('locale', locale);

    if (appContext.userLocation.country) {
      params.append('country', appContext.userLocation.country);
    }

    const platform = browserFeatures.getLowEntropyHints().platform;

    if (platform) {
      params.append('platform', platform);
    }

    const device = browserFeatures.getLowEntropyHints().isMobile ? 'Mobile' : 'Non-Mobile';

    if (device) {
      params.append('device', device);
    }

    return params;
  }, [galleryId, pageSize, locale, appContext.userLocation.country, browserFeatures]);

  const url = `https://newsroom.snap.com/api/gallery?${params}`;

  const { data, isLoading } = useAsyncData({
    dataId: `/api/gallery?${params}`,
    dataAsync: async () => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
      } catch (error) {
        logger.logError({
          component: 'UseNewsroomGalleryTiles',
          message: 'Error loading gallery tiles',
          error,
        });
      }
    },
  });

  if (data?.total) {
    previousTotal.current = data?.total;
  }

  return {
    paginationData: isLoading ? { tiles: [], total: previousTotal.current } : data,
    isLoading,
  };
}
