import { Site } from '@snapchat/mw-common';
import { contentfulSpaceIds, productionContentTokens } from '@snapchat/mw-contentful-schema';
import { useContext, useMemo } from 'react';

import { AppContext } from '../../../AppContext';
import type { ContentfulConfiguration } from '../../../configTypes';
import { defaultLocale } from '../../../helpers/locale';
import { getOrCreateApolloClient } from '../../../utils/contentful/ContentfulClientCache';

export const useNewsroomContentfulContext = () => {
  const { currentLocale } = useContext(AppContext);
  const newsroomLocale = newsroomLocales.has(currentLocale) ? currentLocale : defaultLocale;

  const newsroomContext = useMemo(() => {
    const contentfulConfig: ContentfulConfiguration = {
      spaceId: contentfulSpaceIds[Site.NEWSROOM],
      environmentName: 'production',
      accessToken: productionContentTokens[Site.NEWSROOM],
    };

    const apolloClient = getOrCreateApolloClient(newsroomLocale, [
      contentfulConfig,
      {
        Entry: ['Tile'],
      },
      {},
    ]);

    return {
      locale: newsroomLocale,
      preview: false,
      fetchPolicy: 'cache-first',
      apolloClient,
    };
  }, [newsroomLocale]);

  return newsroomContext;
};

/**
 * Newsroom.snap.com website does not include all of the locales that exist on snap.com. We need to
 * use the default locale (en-US) when a user sees newsroom content outside of the locales bellow.
 */
export const newsroomLocales = new Set<string>([
  'ar',
  'bn-BD',
  'bn-IN',
  'da-DK',
  'de-DE',
  'el-GR',
  'en-GB',
  'en-US',
  'es-AR',
  'es-ES',
  'es-MX',
  'es',
  'fi-FI',
  'fil-PH',
  'fr-FR',
  'gu-IN',
  'hi-IN',
  'id-ID',
  'it-IT',
  'ja-JP',
  'kn-IN',
  'ko-KR',
  'ml-IN',
  'mr-IN',
  'ms-MY',
  'nb-NO',
  'nl-NL',
  'pa',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'ru-RU',
  'sv-SE',
  'ta-IN',
  'te-IN',
  'th-TH',
  'tr-TR',
  'ur-PK',
  'vi-VN',
  'zh-Hans',
  'zh-Hant',
]);
