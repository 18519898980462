import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { GlobalNavItem as GlobalNavItemSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { shouldFlushLogs } from '../../../../helpers/getLocalPath';
import { logger, logUserEvent } from '../../../../helpers/logging';
import { UserAction } from '../../../../types/events';
import { itemListCss, itemsWrapperCss, listItemCss } from './SideMenu.styles';
import type { SideMenuDataItemProps } from './types';

export const SideMenu: FC<{ navItems: SideMenuDataItemProps[] }> = ({ navItems }) => {
  const logSideMenuClick = (url: string) => {
    logUserEvent({
      eventCategory: 'SnapSideMenu',
      eventAction: UserAction.Click,
      eventLabel: `to: ${url}`,
    });

    if (shouldFlushLogs(url)) {
      void logger.flush();
    }
  };

  return (
    <nav className={itemsWrapperCss}>
      <ol className={itemListCss}>
        {navItems?.map((item, index) => (
          <li key={`list-item-${index}`} className={listItemCss}>
            <GlobalNavItemSDS
              title={documentToPlainTextString(item?.title?.json)}
              href={item.url}
              onClick={() => logSideMenuClick(item.url)}
            />
          </li>
        ))}
      </ol>
    </nav>
  );
};
