import { GalleryGridV2 as GalleryGridV2SDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { TileQueryResponse } from '../../../../components/Gallery/query';
import type { ImageDataProps } from '../../../../components/Image/types';
import { SummaryCard } from '../../../../components/SummaryCard';
import { useNewsroomContentfulQuery } from '../../hooks/useNewsroomContentfulQuery';
import { LatestNewsBlockLoading } from './LatestNewsBlockLoading';

type LatestNewsBlockTilesProps = {
  tileIds: string[];
  defaultTileImage?: ImageDataProps;
};

export const LatestNewsBlockTiles: FC<LatestNewsBlockTilesProps> = ({
  tileIds,
  defaultTileImage,
}) => {
  const { data, loading } = useNewsroomContentfulQuery<TileQueryResponse, { ids: string[] }>({
    variables: { ids: tileIds },
  });

  if (loading) {
    return <LatestNewsBlockLoading numOfCards={tileIds.length} />;
  }

  const sortedItems = data?.tileCollection?.items
    .slice()
    .map(item => {
      let url = item.url;
      const slug = item.slugReference?.slug ?? '';

      // If no url is provided, update the url property of the item to ensure that the slug is used in
      // an absolute url to the Newsroom, otherwise the slug will be used as a relative url in SummaryCard
      if (!url) {
        const newsroomUrl = new URL('https://newsroom.snap.com/');
        newsroomUrl.pathname = slug;
        url = newsroomUrl.href;
      }

      return { ...item, url };
    })
    .sort((a, b) => tileIds.indexOf(a.sys.id) - tileIds.indexOf(b.sys.id));

  return (
    <GalleryGridV2SDS>
      {sortedItems?.map(tile => (
        <SummaryCard
          key={tile.sys.id}
          {...tile}
          media={tile.media ?? defaultTileImage}
          // TODO: fetch show* prop values from gallery entry rather than hardcoding
          showDates={true}
          showDescriptions={true}
          showMedia={true}
        />
      ))}
    </GalleryGridV2SDS>
  );
};
