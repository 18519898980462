import type { ImageSources } from '@snapchat/snap-design-system-marketing';
import { Picture as PictureSDS } from '@snapchat/snap-design-system-marketing';
import type { ReactElement } from 'react';

import { Anchor } from '../../../../components/Anchor';
import { shouldFlushLogs } from '../../../../helpers/getLocalPath';
import { logger, logUserEvent } from '../../../../helpers/logging';
import { UserAction } from '../../../../types/events';
import {
  animatedMediaCardBackgroundImageCss,
  animatedMediaCardCss,
  animatedMediaCardForegroundImageCss,
  animatedMediaCardPictureCss,
  animatedMediaCardWrapperCss,
} from './AnimatedMediaCard.styles';

type AnimatedMediaCardProps = {
  url: string;
  backgroundImageSources?: ImageSources;
  foregroundImageSources?: ImageSources;
};

export function AnimatedMediaCard({
  url,
  backgroundImageSources,
  foregroundImageSources,
}: AnimatedMediaCardProps): ReactElement {
  const onClickHandler = () => {
    logUserEvent({
      eventCategory: 'AnimatedMediaCard',
      eventAction: UserAction.Click,
      eventLabel: `to: ${url}`,
    });

    if (shouldFlushLogs(url)) {
      void logger.flush();
    }
  };

  return (
    <Anchor href={url} className={animatedMediaCardCss} onClick={onClickHandler}>
      <div className={animatedMediaCardWrapperCss}>
        <PictureSDS
          imgSrcs={backgroundImageSources}
          imgClassName={animatedMediaCardBackgroundImageCss}
          className={animatedMediaCardPictureCss}
        />
      </div>
      <PictureSDS
        imgSrcs={foregroundImageSources}
        imgClassName={animatedMediaCardForegroundImageCss}
        className={animatedMediaCardPictureCss}
      />
    </Anchor>
  );
}
