import { css } from '@emotion/css';
import { GlobalComponentsContext } from '@snapchat/mw-global-components';
import { useGlobalComponentsContentfulQuery } from '@snapchat/mw-global-components-schema';
import kebabCase from 'lodash-es/kebabCase';
import head from 'lodash/head';
import { useContext } from 'react';
import type { Column } from 'react-table';

import {
  Block,
  Content,
  MessageContext,
  Spinner,
  Table,
} from '../../../../../../snap-design-system-marketing/src';
import { AppContext } from '../../../../AppContext';
import { useContentfulContext } from '../../../../components/useContentfulContext';
import { Config } from '../../../../config';
import { renderRichTextWithElements } from '../../../../utils/renderText/renderRichText';
import {
  type CookieInformationDataProps,
  type CookieModalShallowDataProps,
  type CookieShallowProps,
  cookieInformationQuery,
} from './CookieInformationBlock.query';

const tableCss = css`
  table-layout: fixed;
  width: 100%;
  overflow-wrap: break-word;
  min-width: 600px;
`;

const tableContainerCss = css`
  overflow-x: auto;
`;

export const CookieInformationBlock = () => {
  const context = useContentfulContext();
  const { globalApolloClient } = useContext(GlobalComponentsContext);
  const { currentLocale } = useContext(AppContext);
  const { formatMessage } = useContext(MessageContext);

  const formattedName = formatMessage({ id: 'name', defaultMessage: 'Name' });
  const formattedProvider = formatMessage({ id: 'provider', defaultMessage: 'Provider' });
  const formattedDomains = formatMessage({ id: 'domains', defaultMessage: 'Domains' });
  const formattedPurpose = formatMessage({ id: 'purpose', defaultMessage: 'Purpose' });
  const formattedExpiration = formatMessage({ id: 'expiration', defaultMessage: 'Expiration' });
  const formattedBlockTitle = formatMessage({
    id: 'cookieInformation',
    defaultMessage: 'Cookie Information',
  });
  const formattedUpdated = formatMessage({ id: 'updatedOn', defaultMessage: 'Updated On: ' });

  const { data } = useGlobalComponentsContentfulQuery<CookieInformationDataProps>(
    cookieInformationQuery,
    { currentLocale: context.locale, isPreview: context.preview, isSSR: Config.isSSR },
    { client: globalApolloClient }
  );

  const cookieModal = head(data?.cookieModalCollection.items) as
    | CookieModalShallowDataProps
    | undefined;

  if (!cookieModal) {
    return <Spinner />;
  }

  const cookieModalLastPublished = cookieModal.sys?.publishedAt;
  const cookieModalPublishedDate = new Date(cookieModalLastPublished);
  const formattedDate = cookieModalPublishedDate.toLocaleDateString(currentLocale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const cookieCategories = cookieModal.cookieCategoriesCollection.items;

  const tableColumns: Column<CookieShallowProps>[] = [
    {
      Header: formattedName,
      accessor: 'name',
    },
    {
      Header: formattedProvider,
      accessor: (row: CookieShallowProps) => {
        const rowProvider = row.provider;
        return renderRichTextWithElements(rowProvider);
      },
    },
    {
      Header: formattedDomains,
      accessor: (row: CookieShallowProps) => {
        const rowDomains = row.domains;
        return renderRichTextWithElements(rowDomains);
      },
    },
    {
      Header: formattedPurpose,
      accessor: (row: CookieShallowProps) => {
        const rowPurpose = row.purpose;
        return renderRichTextWithElements(rowPurpose);
      },
    },
    {
      Header: formattedExpiration,
      accessor: (row: CookieShallowProps) => {
        const rowExpiration = row.expiration;
        return renderRichTextWithElements(rowExpiration);
      },
    },
  ];

  const cookieTables = cookieCategories.map(category => {
    const tableTitle = category.title;

    const tableData: CookieShallowProps[] = category.cookiesCollection.items.map(
      (cookie): CookieShallowProps => {
        return {
          name: cookie.name,
          purpose: cookie.purpose,
          provider: cookie.provider,
          expiration: cookie.expiration,
          domains: cookie.domains,
        };
      }
    );

    return (
      <Content
        anchorId={kebabCase(tableTitle.toLowerCase())}
        title={tableTitle}
        key={tableTitle}
        body={
          <section className={tableContainerCss}>
            <Table<CookieShallowProps>
              data={tableData}
              columns={tableColumns}
              className={tableCss}
            />
          </section>
        }
      />
    );
  });

  return (
    <Block
      title={formattedBlockTitle}
      maxColumns={1}
      subtitle={`${formattedUpdated} ${formattedDate}`}
    >
      {cookieTables}
    </Block>
  );
};
