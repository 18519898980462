import { type FC, type PropsWithChildren, useEffect, useLayoutEffect } from 'react';

import { getHeaderHeightFromCssVar } from '../../../../components/Header/headerSizeUtils';
import { snapHeaderBackgroundVar } from '../../styleConstants';
import { snapHomePageWrapperCss } from './SnapHomePageWrapper.styles';

export const SnapHomePageWrapper: FC<PropsWithChildren> = ({ children }) => {
  useLayoutEffect(() => {
    document.body.style.setProperty(snapHeaderBackgroundVar, 'transparent');

    return () => {
      document.body.style.removeProperty(snapHeaderBackgroundVar);
    };
  });

  useEffect(() => {
    const scrollListener = () => {
      const opacityOneLimit = getHeaderHeightFromCssVar();
      const maxOpacity = 0.4;
      const opacity = Math.min(maxOpacity, window.scrollY / opacityOneLimit);
      // NOTE: 255, 252, 0 is the Snap Yellow.
      document.body.style.setProperty(snapHeaderBackgroundVar, `rgba(255, 252, 0, ${opacity})`);
    };

    window.addEventListener('scroll', scrollListener, { passive: true });

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return <section className={snapHomePageWrapperCss}>{children}</section>;
};
