import { gql } from '@apollo/client';
import type { Alignment } from '@snapchat/snap-design-system-marketing';

import { ctaFragment } from '../../../../components/CallToAction';
import type { CallToActionDataProps } from '../../../../components/CallToAction/types';
import { fragments as imageFragments } from '../../../../components/Image';
import type { ImageDataProps } from '../../../../components/Image/types';
import type { ContentfulSysProps } from '../../../../types/contentful';
import type { Items } from '../../../../types/Items';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const newsBlockFragment = {
  all: gql`
    fragment NewsBlockAll on NewsBlock {
      ...ContentfulSysId
      eyebrow
      textAlignment
      textAlignmentMobile
      numberOfNewsItems
      defaultImage {
        ...ImageAll
      }
      callsToActionCollection(limit: 2) {
        items {
          ... on CallToAction {
            ...CallToActionAll
          }
        }
      }
      newsReference {
        sys {
          urn
        }
      }
    }
    ${contentfulSysIdFragment}
    ${ctaFragment}
    ${imageFragments.all}
  `,
};

export const newsBlockQuery = gql`
  query NewsBlockQuery($preview: Boolean!, $locale: String!, $id: String!) {
    newsBlock(preview: $preview, locale: $locale, id: $id) {
      ...NewsBlockAll
    }
  }
  ${newsBlockFragment.all}
`;

export type NewsBlockDataProps = ContentfulSysProps & {
  eyebrow: string;
  textAlignment: Alignment;
  textAlignmentMobile: Alignment;
  numberOfNewsItems: number;
  defaultImage?: ImageDataProps;
  callsToActionCollection?: Items<CallToActionDataProps>;
  newsReference: {
    sys: {
      urn: string;
    };
  };
};

export type NewsBlockDataHandlerProps = {
  newsBlock: NewsBlockDataProps;
};
