import { css } from '@emotion/css';
import { m, smallMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const contentContainerCss = css`
  display: flex;
  flex-direction: column;
  margin-bottom: ${m('--spacing-xxxl')};

  ${smallMobileMediaQuery} {
    margin-bottom: ${m('--spacing-xl')};
  }

  /* override background color for child SDS-M Block components so the background animation is visible */
  .sdsm-block {
    background-color: transparent;
  }
`;
