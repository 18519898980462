import {
  GalleryGridV2 as GalleryGridV2SDS,
  SkeletonSummaryCard,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

type LatestNewsBlockLoadingProps = {
  numOfCards?: number;
};

export const LatestNewsBlockLoading: FC<LatestNewsBlockLoadingProps> = ({ numOfCards = 2 }) => {
  return (
    <GalleryGridV2SDS>
      {Array.from({ length: numOfCards }).map((_, index) => (
        <SkeletonSummaryCard key={index} />
      ))}
    </GalleryGridV2SDS>
  );
};
