import { cx } from '@emotion/css';
import { Block as BlockSDS, Media as MediaSDS } from '@snapchat/snap-design-system-marketing';
import debounce from 'lodash-es/debounce';
import { type FC, useEffect, useRef, useState } from 'react';

import { getRichTextWithEmbeddingsRenderer } from '../../../../utils/renderText/renderRichText';
import type { SnapHomeHeroDataProps } from './SnapHomeHero.query';
import {
  ghostImageBaseCss,
  ghostImageBottomCss,
  ghostImageMiddleCss,
  ghostImageTopCss,
  ghostImageVisibleCss,
  heroBodyCss,
  heroContainerCss,
  heroTitleCss,
} from './SnapHomeHero.styles';
import type { SnapHomeHeroImageProperties } from './types';

export const SnapHomeHero: FC<SnapHomeHeroDataProps> = ({ title, title2, title3, media, body }) => {
  const lines = [title];
  if (title2) lines.push(title2);
  if (title3) lines.push(title3);

  const lineContainerRef = useRef<HTMLDivElement | null>(null);
  const lineRefs = useRef<(HTMLSpanElement | null)[]>(Array.from({ length: lines.length }));
  const [imageProperties, setImageProperties] = useState<SnapHomeHeroImageProperties>({
    width: 0,
    height: 0,
    position: 'Top',
    isVisible: false,
  });

  // Fired immediately at start of resize event queue
  useEffect(() => {
    const detectResize = debounce(
      () => {
        setImageProperties(current => ({ ...current, isVisible: false }));
      },
      500,
      {
        leading: true,
        trailing: false,
      }

      // Fired at end of resize event queue
    );

    const setGhostPosition = debounce(() => {
      const deviceWidth = window.innerWidth;
      const isTablet = deviceWidth < 1025;
      const containerWidth = lineContainerRef.current?.offsetWidth ?? 0;
      const firstLineHeight = lineRefs.current[0]?.offsetHeight ?? 0;
      const firstLineWidth = lineRefs.current[0]?.offsetWidth ?? 0;
      const lastLineHeight = lineRefs.current[lines.length - 1]?.offsetHeight ?? 0;
      const lastLineWidth = lineRefs.current[lines.length - 1]?.offsetWidth ?? 0;
      const maxLineWidth = lineRefs.current.reduce((maxWidth, currentLine) => {
        const lineWidth = currentLine?.offsetWidth ?? 0;
        return Math.max(maxWidth, lineWidth);
      }, 0);
      const useTopAlignedMedia = firstLineWidth < lastLineWidth;
      const useRightAlignedMedia =
        isTablet && maxLineWidth < containerWidth * 0.6; /* arbitrary cutoff at 60% of width */

      const paddingSizeX = 0; /* adjust position to account for horizontal padding */
      const paddingSizeY = 32; /* adjust position to account for vertical padding */

      /* If there isn't sufficent space, just don't render the media  */
      const useNoMedia = useTopAlignedMedia && containerWidth - firstLineWidth - paddingSizeX < 50;

      if (useNoMedia) {
        setImageProperties(current => ({ ...current, isVisible: false }));
      } else if (useRightAlignedMedia) {
        setImageProperties({
          width: containerWidth - maxLineWidth - paddingSizeX,
          height: firstLineHeight * lines.length /* assumes consistent line height */,
          position: 'Middle',
          isVisible: true,
        });
      } else if (useTopAlignedMedia) {
        setImageProperties({
          width: containerWidth - firstLineWidth - paddingSizeX,
          height: firstLineHeight + paddingSizeY,
          position: 'Top',
          isVisible: true,
        });
      } else {
        setImageProperties({
          width: containerWidth - lastLineWidth - paddingSizeX,
          height: lastLineHeight + paddingSizeY,
          position: 'Bottom',
          isVisible: true,
        });
      }
    }, 500);

    const handleResize = () => {
      detectResize();
      setGhostPosition();
    };

    setGhostPosition();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderBody = getRichTextWithEmbeddingsRenderer(false, false);

  if (!media?.media) return null;

  const desktopMedia = media.media;
  const mobileMedia = media.mobileMedia;

  // Placeholder for logic to populate `imgSrcs` prop (removed as part of hotfix)

  return (
    <BlockSDS maxColumns={1}>
      <div className={heroContainerCss}>
        {/* First column */}
        <div ref={lineContainerRef} className={heroTitleCss}>
          <h1>
            {lines.map((l, i) => (
              <span key={i}>
                <span
                  ref={el => {
                    lineRefs.current[i] = el;
                  }}
                >
                  {l}
                </span>
              </span>
            ))}
            <span
              style={{ width: imageProperties.width, height: imageProperties.height }}
              className={cx(ghostImageBaseCss, {
                [ghostImageVisibleCss]: imageProperties.isVisible,
                [ghostImageTopCss]: imageProperties.position === 'Top',
                [ghostImageMiddleCss]: imageProperties.position === 'Middle',
                [ghostImageBottomCss]: imageProperties.position === 'Bottom',
              })}
            >
              <MediaSDS
                // TODO: refactor this in follow up ticket
                // imgSrcs={imgSrcs}
                imageSource={desktopMedia.url}
                altText={media.media.description}
                videoSource={desktopMedia.url}
                sourceType={desktopMedia.contentType}
                mobileVideoSource={mobileMedia?.url}
              />
            </span>
          </h1>
        </div>

        {/* Second column */}
        {body && <div className={heroBodyCss}>{renderBody(body)}</div>}
      </div>
    </BlockSDS>
  );
};
