import type { FC } from 'react';

import type { ImageDataProps } from '../../../../components/Image/types';
import { useNewsroomContentfulContext } from '../../context/useNewsroomContentfulContext';
import { useNewsroomGalleryTiles } from '../../hooks/useNewsroomGalleryTiles';
import { LatestNewsBlockTiles } from './LatestNewsBlockTiles';

type LatestNewsBlockGalleryProps = {
  galleryId: string;
  defaultTileImage?: ImageDataProps;
  pageSize?: number;
};

export const LatestNewsBlockGallery: FC<LatestNewsBlockGalleryProps> = ({
  galleryId,
  defaultTileImage,
  pageSize,
}) => {
  const { locale } = useNewsroomContentfulContext();

  const { paginationData } = useNewsroomGalleryTiles({
    galleryId,
    locale,
    pageSize,
  });

  const tileIds = paginationData?.tiles?.map(tile => tile.tileSysId) ?? [];

  return tileIds?.length ? (
    <LatestNewsBlockTiles tileIds={tileIds} defaultTileImage={defaultTileImage} />
  ) : null;
};
